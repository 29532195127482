'use client';

import {
  ExplorationBrandRefreshGlobalStyle,
  explorationBrandRefreshTheme,
  explorationDarkTheme,
} from '@thrivent-web/exploration/brandRefreshTheme';
import { ReactElement } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

type Props = {
  children: ReactElement | ReactElement[];
  makeDark: boolean;
};

// This overrides the overflow: hidden that onetrust attaches
// !important is unfortunately needed, since the overflow: hidden is inline
export const GlobalStyleOverride = createGlobalStyle`
    body {
      overflow: initial !important;
    }
`;

export const Theme = ({ makeDark, children }: Props) => {
  const usedTheme = makeDark
    ? explorationDarkTheme
    : explorationBrandRefreshTheme;
  return (
    <ThemeProvider theme={usedTheme}>
      <ExplorationBrandRefreshGlobalStyle />
      <GlobalStyleOverride />
      {children}
    </ThemeProvider>
  );
};
